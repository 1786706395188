import React from 'react';
import Layout from '../components/layout';
import { useStaticQuery, graphql, Link } from 'gatsby';
import MasterEyebrows from '../components/masterEyebrows';
import { GatsbyImage } from 'gatsby-plugin-image';

const SEO = {
  'title': 'Not found',
  'author': 'Deutsch LA',
  'keywords': 'Deutsch LA',
};

const NotFoundPage: React.FC = () => {
  const homeLink = (
    <Link
      to="/"
      className="link-style mobile:text-base tablet:text-2lg mobile:leading-loose
    tablet:leading-5 tracking-wide font-normal"
    >
      home page
    </Link>
  );
  const data = useStaticQuery(graphql`
    {
      errorImage: file(relativePath: { eq: "404.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);
  return (
    <Layout seo={SEO}>
      <MasterEyebrows eyebrow1="404 ERROR" eyebrow2="" textColor="text-white">
        <div className="text-white container mx-auto flex flex-nowrap">
          <div className="mx-auto mobile:w-11/12 laptop-standard:w-8/12 laptop-large:w-7/12 mobile:pt-15 laptop-standard:pt-26 space-y-3">
            <GatsbyImage
              image={data.errorImage.childImageSharp.gatsbyImageData}
              className="w-full"
              alt="loading image"
            />
            <h3 className="text-px36 leading-px43 tracking-desk font-black">404 ERROR</h3>
            <div
              className="mobile:text-base tablet:text-2lg mobile:leading-loose
            tablet:leading-5 tracking-wide font-normal space-y-2"
            >
              <p>Didn’t find what you are looking for?</p>
              <p>Don’t worry. Go to the {homeLink} and check out our projects and articles.</p>
            </div>
          </div>
        </div>
      </MasterEyebrows>
    </Layout>
  );
};

export default NotFoundPage;
